<template>
    <div>
        
        <h1 class="mt-2 mb-4">{{trans('pdw-risky-acts',235)}}</h1>

        <b-alert variant="success" :show="success_dismiss_count_down" @dismiss-count-down="countDownChangedSuccess" fade >
            <div>
                    <b-icon icon="check-circle-fill" font-scale="1" class="mr-3"></b-icon>
                    <span class="align-middle">{{trans('successfully-saved',253)}}</span>
            </div>
            
        </b-alert>

        <b-alert variant="danger" :show="unsuccess_dismiss_count_down" @dismiss-count-down="countDownChangedUnsuccess" fade >
            <div>
                    <b-icon icon="x-circle-fill" font-scale="1" class="mr-3"></b-icon>
                    <span class="align-middle">{{trans('unsuccessfull-save',253)}}</span>
            </div>
            
        </b-alert>

        <b-overlay :show="loading" :opacity="0.0">

            <b-card class="mb-3" v-if="cItemData.competences != null">
                
                <b-row v-if="array_functions.some( f => JSON.parse(cItemData.ids_function_data_main).includes(f)) && !loading_competences">
                    <b-col class="mb-1">
                        <div class="flabel">{{trans('pdw-select-certified-reservations',235)}}</div>
                        <div class="filters">
                            <b-button class="app-local-btn" :variant="selected_all ? 'students' : 'outline-students'" v-on:click="selectAll()" id="all" :style="is_mobile.small ? {width: '100%'}: {}">
                                {{trans('pdw-select-all',235)}}
                            </b-button>
                            <b-button class="app-local-btn" v-for="item in cItemData.competences" :key="item.id_competence_const_group" :variant="item.selected ? 'students' : 'outline-students'" v-on:click="selectOne(item)" :style="is_mobile.small ? {width: '100%'}: {}">
                                {{item.competence_group_name}}
                            </b-button>
                        </div>
                    </b-col>
                </b-row>

                <b-row v-if="!array_functions.some( f => JSON.parse(cItemData.ids_function_data_main).includes(f))">
                    <b-col class="mb-1">
                        <div class="flabel">{{trans('pdw-for-the-function',235)}} {{lowerCase(cItemData.function_name ? cItemData.function_name : '')}} {{trans('pdw-risky-actions-no-need',235)}}</div>
                    </b-col>
                </b-row>
            </b-card>

            <div v-for="(item,index_group) in cItemData.competences" :key="item.id_competence_const_group">
                <b-card class="mb-3" v-if="item.selected == 1">
                    <app-row-left-label :slots="[5,7]">
                        <template v-slot:col-1>
                            <label class="app-input-top-label">{{item.competence_group_name}}</label>
                        </template>
                        <template v-slot:col-2>
                            <label class="app-input-top-label" v-if="!is_mobile.value">{{trans('pdw-enter-expiration-date',235)}}</label>
                        </template>
                    </app-row-left-label>

                    <app-row-left-label :slots="[5,7]" v-for="(competence, index_type) in item.competences" :key="competence.id_competence_const_type" :isMarginBottom="false" class="mb-1">
                        <template v-slot:col-1>
                            <app-check-box :id="'checkbox_' + competence.id_competence_const_type.toString()" v-model="competence.selected" class="custom-control-primary mr-4" value="1" unchecked-value="0" size="lg" :rightLabel="competence.competence_name" v-on:change="changeSelected(competence)" :minHeight="true" />
                        </template>

                        <template v-slot:col-2>
                            <b-row v-if="competence.selected == '1' ">
                                <b-col cols="9">
                                    <app-date v-model="competence.competence_date" :validatorRules="competence.selected == '1' ? 'required|date-check|date-more-than:'+date_start : '' " :disabledDatesRange="disabledDatesRange" :validatorName="competence.competence_name" :validatorCustomMessage="{ required: trans('date-required',256) }" popupClass="app-students"/>
                                   <!-- <app-input v-if="competence.competence_date" type="hidden" v-model="competence.id_document_data_main" name="Document" validatorRules="required" :validatorCustomMessage="{ required: trans('pw-document-required',235) }"/> -->
                                </b-col>
                                <b-col cols="1" class="app-local-row">
                                    <img :id="'popover_' + _uid.toString() +competence.id_competence_const_type.toString()" tabindex="0" src="@/assets/images/icons/info-button-students.svg" height="18px" style="vertical-align: text-top;" class="" @click="openPopover(competence.id_competence_const_type)" />
                                </b-col>
                                <b-col cols="2" class="app-local-row-upload mr-1">
                                    <app-button v-if="!competence.id_document_data_main" class="btn-outline-students" :loading="competence.uploading" @click="openUpload(competence.id_competence_const_type)">{{trans('pw-upload',235)}}</app-button>
                                    <b-form-file style="display:none" :id="'upload_'+ _uid.toString() +competence.id_competence_const_type.toString()" v-on:change="addFile($event, competence.id_competence_const_type, index_group, index_type)"></b-form-file>

                                    <app-button v-if="competence.id_document_data_main" @click="deleteFile(competence.id_document_data_main, index_group, index_type)" icon="x-circle-fill" type="outline-danger" :loading="competence.uploading">{{trans('verwijder',255)}}</app-button>
                                </b-col>
                            </b-row>
                        </template>
                    </app-row-left-label>

                </b-card>
            </div>

            <b-card class="mb-3" v-if="cItemData.competences != null">
                <app-row-left-label>
                    <app-check-box id="condition_privacy" v-model="cItemData.condition_privacy" class="custom-control-primary mr-4" value="1" unchecked-value="0" size="lg" validatorRules="required" :validatorCustomMessage="{ required: trans('condition-privacy-required',256) }" >
                        <template v-slot:custom-right-label > 
                            {{trans('pdw-condition-privacy-p21', 235)}} <b-link class="font-weight-600" target="_blank" :href="cItemData.privacy_policy_link">{{trans('pdw-condition-privacy-p22', 235)}}</b-link> {{trans('pdw-condition-privacy-p23', 235)}}
                        </template>  
                    </app-check-box>
                </app-row-left-label>

                <app-row-left-label>
                    <app-check-box id="condition_confirmation" v-model="cItemData.condition_confirmation" class="custom-control-primary mr-4" value="1" unchecked-value="0" size="lg" :rightLabel="trans('pdw-condition-confirmation',235)" validatorRules="required" :validatorCustomMessage="{ required: trans('pdw-condition-confirmation-required',235) }" />
                </app-row-left-label>

                <app-row-left-label>
                    <app-check-box id="condition_profiling" v-model="cItemData.condition_profiling" class="custom-control-primary mr-4" value="1" unchecked-value="0" size="lg" :rightLabel="trans('pdw-condition-profiling',235)" validatorRules="required" :validatorCustomMessage="{ required: trans('required-field',256) }" />
                </app-row-left-label>

                <app-row-left-label>
                    <app-check-box id="condition_complete" v-model="cItemData.condition_complete" class="custom-control-primary mr-4" value="1" unchecked-value="0" size="lg" :rightLabel="trans('pdw-complete-confirmation-2',235)" validatorRules="required" :validatorCustomMessage="{ required: trans('required-field',256) }" />
                </app-row-left-label>
            </b-card>
            
            <template #overlay>
                <div></div>
            </template>
        </b-overlay>
         
        <b-popover v-if="popover_show" :target="popover_target"  triggers="focus" no-fade :show.sync="popover_show" placement="top" @hide="popoverHide">
            <p class="app-popover-text">{{trans('pw-risico-max-3-years',235)}}</p>
        </b-popover>
    </div>
</template>

<script>
import axios from "@axios";
import moment from 'moment'

export default {
    components: {
    },

    props: ["itemData", "showSuccessAlert", "showUnsuccessAlert", "loading"],

    watch:{
        showSuccessAlert(val){
            this.success_dismiss_count_down = this.dismiss_secs;
        },

        showUnsuccessAlert(){
            this.unsuccess_dismiss_count_down = this.dismiss_secs;
        },

        "cItemData.condition_confirmation": {
            handler: function(val) {
                if(this.cItemData.condition_confirmation == false)
                    this.cItemData.condition_confirmation = null;
            },
        },

        "cItemData.condition_privacy": {
            handler: function(val) {
                if(this.cItemData.condition_privacy == false)
                    this.cItemData.condition_privacy = null;
            },
        },

        "cItemData.condition_profiling": {
            handler: function(val) {
                if(this.cItemData.condition_profiling == false)
                    this.cItemData.condition_profiling = null;
            },
        },

        "cItemData.condition_complete": {
            handler: function(val) {
                if(this.cItemData.condition_complete == false)
                    this.cItemData.condition_complete = null;
            },
        },
    },

    computed:{
       cItemData: {
            get() { return this.itemData },
            set(value) { this.$emit('update:itemData', value) }, 
        },
    },

    data() {
        return {
            dismiss_secs: 3,
            success_dismiss_count_down: 0,
            unsuccess_dismiss_count_down: 0,
            loading_competences: false,
            competences_items: null,
            selected_all: this.itemData.selected_all_competences,
            array_functions: [1,4,6,17,26,3,10,11,16,15,18,24,23,9,8,19,20,25,7],
            refresh_buttons: 0,
            date_start: moment().add(1, "days").subtract(3, 'years').toDate(),   
            disabledDatesRange: '(date < new Date('+moment().add(1, "days").subtract(3, 'years')+'))',
            popover_show: false,
            popover_target: '', 
        }
    },

    created(){
        if(this.cItemData.condition_confirmation == false){
            this.cItemData.condition_confirmation = null;
        }
        if(this.cItemData.condition_privacy == false){
            this.cItemData.condition_privacy = null;
        }

        if(this.cItemData.condition_profiling == false){
            this.cItemData.condition_profiling = null;
        }

        if(this.cItemData.condition_complete == false){
            this.cItemData.condition_complete = null;
        }
    },

    methods: {
        openUpload(id_competence_const_type) {
            document.getElementById('upload_' + this._uid.toString() + id_competence_const_type.toString()).click()
        },
        openPopover(id_competence_const_type) {
            if (this.popover_show == false) { 
                this.popover_target = 'popover_' + this._uid.toString() + id_competence_const_type.toString();
                this.popover_show = true;
            }
        },
        popoverHide() {
             this.popover_target = '';
             this.popover_show = false;
        },
        countDownChangedSuccess(success_dismiss_count_down) {
            this.success_dismiss_count_down = success_dismiss_count_down;
        },

        countDownChangedUnsuccess(unsuccess_dismiss_count_down){
            this.unsuccess_dismiss_count_down = unsuccess_dismiss_count_down;
        },

        addFile(e, id_competence_const_type, index_group, index_type) {

            var file = e.target.files[0];

            if(file.size > 15728640){
                alert(this.trans('too-large-file', 180));
                file = "";
                return;
            }

            let formData = new FormData();
            formData.append("id_person_data_main", this.cItemData.id_person_data_main);
            formData.append("id_competence_const_type", id_competence_const_type);
            formData.append("file", file);
            formData.append("file_name", file.name);
            formData.append("id_document_const_type", 67);
            formData.append("file_size", file.size);

            this.sendFile(index_group, index_type, formData);

        },

        sendFile(index_group, index_type, formData){
            const config = {
                headers: { "content-type": "multipart/form-data" },
            };

            this.cItemData.competences[index_group].competences[index_type].uploading = true;

            axios
                .post("students/data-wizard/addFile/" + this.$route.params.token, formData, config)
                .then((response) => {
                    this.cItemData.competences[index_group].competences[index_type].id_document_data_main = response.data
                    this.cItemData.competences[index_group].competences[index_type].uploading = false;
                })
                .catch(function(error) {
                    this.cItemData.competences[index_group].competences[index_type].uploading = false;

                    console.log(error);
                });

        },

        deleteFile: function(id_document_data_main, index_group, index_type) {
            this.cItemData.competences[index_group].competences[index_type].uploading = true;

            let formData = new FormData();
            formData.append("id_person_data_main", this.cItemData.id_person_data_main);
            formData.append("id_document_data_main", id_document_data_main);
            formData.append("id_document_const_type", 67);

            axios
                .post("students/data-wizard/deleteFile/" + this.$route.params.token, formData)
                .then((response) => {

                    this.cItemData.competences[index_group].competences[index_type].id_document_data_main = null;
                    this.cItemData.competences[index_group].competences[index_type].uploading = false;
                })
                .catch((error) => {
                    console.error(`error during deleting files: ${error}`);
                });
        },

        selectAll(){
            
            if(this.selected_all == 0){
                this.selected_all = 1;
                Object.keys(this.cItemData.competences).forEach((item) => {
                    this.cItemData.competences[item].selected = 1;
                });
                
            }else{
                this.selected_all = 0;
                Object.keys(this.cItemData.competences).forEach((item) => {
                    this.cItemData.competences[item].selected = 0;

                    this.cItemData.competences[item].competences.forEach(competence => {
                        competence.selected = 0;
                        competence.competence_date = null;
                    });

                });

            }
        },

        selectOne(item){
            if(item.selected == 1){
                item.selected = 0;
                this.selected_all = 0;

                item.competences.forEach(competence => {
                    competence.selected = 0;
                    competence.competence_date = null;
                });

            }else{
                item.selected = 1;
            }
            
        },

        changeSelected(item){
            if(item.selected == '0'){
                item.competence_date = null;
            }
                
        },

        lowerCase(text){
            return text.toLowerCase();
        }
    },


};
</script>
<style>
.b-selected {
    filter: brightness(0) invert(1);
}

.app-local-btn{
    margin-top: 10px;
}
</style>

<style scoped>
.app-local-row-upload{
    padding-left: 0px !important;
    max-width: 40px;
}
.app-local-row{
    padding-left: 0px !important;
    padding-top: 5px;
    max-width: 30px;
}
</style>