<template>
    <div>
                
        <app-row-left-label :slots="[4,2,6]">
            <template v-slot:col-1>
                <app-input :label="trans('pdw-first-name',233)" v-model="cItemData.person_reference.first_name" validatorRules="required" validatorName="Voornaam"  mask="letters-20" @change="checkReference()"/>
            </template>

            <template v-slot:col-2>
                <app-input v-model="cItemData.person_reference.inbetween_name" :label="trans('pdw-middle-name',233)" mask="lettersE1-10" />
            </template>

            <template v-slot:col-3>
                <app-input v-model="cItemData.person_reference.last_name" :label="trans('pdw-last-name',233)" validatorRules="required" validatorName="Achternaam" mask="lettersE1-30" @change="checkReference()"/>
            </template>
        </app-row-left-label>

        <app-row-left-label :slots=" [6,6]">
            <template v-slot:col-1>
                <app-input v-model="cItemData.person_reference.function" :label="trans('function',253)" validatorRules="required" validatorName="Functie" @change="checkReference()"/>
            </template>

            <template v-slot:col-2>
                <app-input v-model="cItemData.person_reference.organisation" :label="trans('cdw-organization',237)" validatorRules="required" validatorName="Organisatie" @change="checkReference()"/>
            </template>
        </app-row-left-label>

        <app-row-left-label :slots="[6,6]" class="mb-3">
            <template v-slot:col-1>
                <app-email :email.sync="cItemData.person_reference.email"  :label="trans('pdw-reference-email',234)" :currentError="email_phone_error" validatorName="Email" customSpinnerClass="app-local-input-spinner" />
            </template>

            <template v-slot:col-2>
                <label class="flabel mb-0"> {{trans('pdw-enter-phone-number',233)}} </label>
                    <app-phone :phone.sync="cItemData.person_reference.phone_number" :prefix.sync="cItemData.person_reference.phone_prefix" :isRequired="false" :currentError="email_phone_error" />
            </template>  
        </app-row-left-label>

        <b-row class="app-local-margin p-0">
            <b-col>
                <app-input ref="email_phone_ref" v-model="email_phone" type="hidden" validatorRules="required" validatorName="email_phone" :validatorCustomMessage="{required: trans('email-or-phone-required', 261)}"/>
            </b-col>
        </b-row>

    </div>
    
</template>
<script>

import AppEmail from '@core/components/AppEmail.vue';
import AppPhone from '@core/components/AppPhone.vue';
export default {

    props:{
        itemData: Object,
        refresh_reference_error: {}
    },

    components: {
        AppEmail,
        AppPhone
    },

    created() {
        this.cItemData.person_reference.phone_prefix =  this.cItemData.person_reference.phone_prefix != null ? this.cItemData.person_reference.phone_prefix : "+31";
    },

    mounted(){
        if((this.cItemData.person_reference.phone_number != null && this.cItemData.person_reference.phone_number != '')  || (this.cItemData.person_reference.email != null && this.cItemData.person_reference.email != '')){
            this.email_phone = true;
        }
    },

    computed: {
        cItemData:{
            get() { return this.itemData },
            set(value) { this.$emit("update:itemData", value) }
        },
        
        cRefreshReferenceError:{
            get() { return this.refresh_reference_error },
            set(value) { this.$emit("update:refresh_reference_error", value) }
        },
    },

    watch: {
        "cItemData.person_reference.phone_number": {
            handler: function(val) {
                this.checkEmailAndPhone();
                if((this.cItemData.person_reference.phone_prefix == '+31' &&  this.cItemData.person_reference.phone_number.length >= 9) || 
                (this.cItemData.person_reference.phone_prefix != '+31' &&  this.cItemData.person_reference.phone_number.length >= 5)){
                    this.checkReference()
                }
            },
            deep:true,
        },

        "cItemData.person_reference.email": {
            handler: function(val) {
                this.checkEmailAndPhone();
                this.checkReference()
            },
            deep:true,
        },
    },

    data(){
        return{
            email_phone: null,
            email_phone_error: false,
        }
    },

    methods: {
        checkReference(){
            if(this.cItemData.person_reference.first_name != null && this.cItemData.person_reference.first_name != '' && this.cItemData.person_reference.last_name != null && 
                this.cItemData.person_reference.last_name != '' && this.cItemData.person_reference.function != null && this.cItemData.person_reference.function != '' && 
                this.cItemData.person_reference.organisation != null && this.cItemData.person_reference.organisation != '' && ( (this.cItemData.person_reference.email != null && 
                this.cItemData.person_reference.email != '' && /^[\W]*([\w+\-.%]+@[\w\-.]+\.[A-Za-z]{2,4}[\W]*,{1}[\W]*)*([\w+\-.%]+@[\w\-.]+\.[A-Za-z]{2,4})[\W]*$/.test(this.cItemData.person_reference.email)) 
                || ( this.cItemData.person_reference.phone_number != null && this.cItemData.person_reference.phone_number != ''))){
                    this.cRefreshReferenceError++;
            }
        },

        async checkEmailAndPhone(){
            if((this.cItemData.person_reference.email == null || this.cItemData.person_reference.email == '') && (this.cItemData.person_reference.phone_number == null || this.cItemData.person_reference.phone_number == '')){
                    this.email_phone = null; 
                    this.email_phone_error = true;
                    await this.$nextTick();
                    this.$refs.email_phone_ref.$refs.provider.validate();
                    return false;
                } else {
                    this.email_phone = true;
                    this.email_phone_error = false;
                    await this.$nextTick();
                    this.$refs.email_phone_ref.$refs.provider.validate();
                    return true;
                }
        },
    }
    
}
</script>
<style scoped>
.app-local-margin{
    margin-top: -30px !important;
}
</style>