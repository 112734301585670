<template>
    <div>
        
        <app-collapse accordion type="margin" class="mb-3">
            
            <b-card no-body class="app-card-wizard mb-3">
                <app-collapse-item style="box-shadow:none !important;" :title="trans('pdw-id-card',234)" :id="'item1'" >
                    <app-row-left-label class="mb-3" :slots="[6,6]">
                        <template v-slot:col-1>
                            <app-radio-group :label="trans('pdw-type-of-id',234)" :options="document_type" v-model="citemData.document_type" validatorName="DocumentType" validatorRules="required" :validatorCustomMessage="{ required: trans('required-field',256) }" @change="checkId()"/>
                            <small class="text-muted">{{trans('pdw-only-id-issued-by-dutch-government',234)}}</small><br/>
                        </template>
                    </app-row-left-label>

                    <app-row-left-label :slots="[6,6]" :isMarginBetween="false" class="mb-3">
                        <template v-slot:col-1>
                            <app-input :label="trans('pdw-enter-bsn',234)" v-model="citemData.bsn_number" validatorName="BSN nummer" validatorRules="required|sofi-number" v-mask="'#########'" @change="checkId()"/>
                        </template>
                    </app-row-left-label>
                    
                    <students-applicants-profile-wizard-document-id-component :itemData="itemData" :refresh_id_error.sync="refresh_id_error"/>

                    <students-applicants-profile-wizard-document-component v-if="itemData.document_type == 3" class="mt-3" :id_document_const_type="85" :maxFilesNumber="1" :itemData="itemData" :document="itemData.document_bsn" />
                </app-collapse-item>       
                <validation-provider :rules="(itemData.document_type == 3 && itemData.document_bsn.status != 1) ? 'required' : ''" #default="{ errors }" :name="trans('pdw-bsn-document-label',  234)">
                    <small class="text-danger ml-1" v-if="itemData.document_bsn.status != 1 && errors[0] != null">{{ errors[0] }}</small>
                </validation-provider>
                
                <validation-provider :key="refresh_id_error" :rules="(itemData.document_id.status == 1 && itemData.document_type != null && itemData.bsn_number != null && itemData.bsn_number != '') ? '' : 'required'" #default="{ errors }" name="Identificatiebewijs" :vid="'id_data'">
                    <small class="text-danger ml-1" v-if="errors[0] != null">{{ errors[0] }}</small>
                </validation-provider>
            </b-card>

            <b-card no-body class="app-card-wizard mb-3">
                <!--<div class="app-card-profile-title">Upload hier je vereiste documenten</div>-->
                <app-collapse-item style="box-shadow:none !important;" :title="trans('pdw-bank-details',234)" :id="'item6'">
                    <students-applicants-profile-wizard-bank-details-component :itemData="itemData" :refresh_bank_error.sync="refresh_bank_error"/>
                </app-collapse-item>
                
                <validation-provider :key="refresh_bank_error" #default="{ errors }" name="Bankpas" :vid="'bank_data'">
                    <small class="text-danger ml-1" v-if="errors[0] != null">{{ errors[0] }}</small>
                </validation-provider>
            </b-card>

            <b-card no-body class="app-card-wizard mb-3">
                <app-collapse-item style="box-shadow:none !important;" :title="trans('pdw-registration-proof',234)" :id="'item2'">
                    <students-applicants-profile-wizard-document-component :id_document_const_type="44"  :maxFilesNumber="1" :itemData="itemData" :document="itemData.document_bvi" />
                </app-collapse-item>
                <validation-provider :rules="itemData.document_bvi.status == 1 ? '' : 'required'" #default="{ errors }" :name="trans('pdw-registration-proof',234)">
                    <small class="text-danger ml-1" v-if="itemData.document_bvi.status != 1 && errors[0] != null">{{ errors[0] }}</small>
                </validation-provider>
            </b-card>

            <b-card no-body class="app-card-wizard mb-3">
                <app-collapse-item style="box-shadow:none !important;" :title="trans('pdw-declaration-of-conduct',234)" :id="'item3'">
                    <label class="app-local-label">{{trans('pdw-vog-description',234)}}</label>
                    <app-radio-group v-model="citemData.is_vog_document" :options="vog_option" :stacked="true" class="app-local-radio-vog"/>
                   <students-applicants-profile-wizard-document-component  v-if="citemData.is_vog_document != null && citemData.is_vog_document == 1" :id_document_const_type="9" class="mt-4" :maxFilesNumber="1" :itemData="itemData" :document="itemData.document_verklaring" />
                </app-collapse-item>

                <validation-provider :rules="citemData.is_vog_document != null ? '' : 'required'" v-if="citemData.is_vog_document == null"  #default="{ errors }" :custom-messages="{ required: trans('required-field',256) }">
                    <small class="text-danger ml-1" v-if="citemData.document_verklaring.status != 1 && errors[0] != null">{{ errors[0] }}</small>
                </validation-provider>

                <validation-provider :rules="(citemData.document_verklaring.status == 1 || citemData.is_vog_document == null || citemData.is_vog_document != 1)  ? '' : 'required'" v-if="citemData.is_vog_document != 0"   #default="{ errors }" name="Verklaring omtrent gedrag">
                    <small class="text-danger ml-1" v-if="citemData.document_verklaring.status != 1 && errors[0] != null">{{ errors[0] }}</small>
                </validation-provider>
            </b-card>

            <b-card no-body class="app-card-wizard mb-3">
                <app-collapse-item style="box-shadow:none !important;" title="CV" :id="'item4'">
                    <students-applicants-profile-wizard-document-component :id_document_const_type="7" :maxFilesNumber="1" :itemData="itemData" :document="itemData.document_cv" accept_files="application/msword, application/pdf" />
                </app-collapse-item>
                <validation-provider :rules="itemData.document_cv.status == 1 ? '' : 'required'" #default="{ errors }" name="CV">
                    <small class="text-danger ml-1" v-if="itemData.document_cv.status != 1 && errors[0] != null">{{ errors[0] }}</small>
                </validation-provider>
            </b-card>

             <b-card no-body class="app-card-wizard mb-3">
                <app-collapse-item style="box-shadow:none !important;" title="" :id="'item7'">
                    <template v-slot:header>
                        <span class="lead collapse-title mr-1">{{trans('pdw-reference',234)}}</span>
                        <img id="popover-target-2" tabindex="0" src="@/assets/images/icons/info-button-students.svg" height="18px" style="vertical-align: text-top; color: #b5d5f3;" class="cursor-pointer" @click="showReferenceInfo" />
                    </template>
                    <students-applicants-profile-wizard-reference-component :itemData="itemData" :refresh_reference_error.sync="refresh_reference_error"/>
                </app-collapse-item>
                <validation-provider :key="refresh_reference_error" #default="{ errors }" name="Referentie" :vid="'reference_data'">
                    <small class="text-danger ml-1" v-if="errors[0] != null">{{ errors[0] }}</small>
                </validation-provider>
            </b-card>
            
            <b-card no-body class="app-card-wizard mb-3">
                <!--<div class="app-card-profile-title">Upload hier je optionele documenten</div>-->
                <app-collapse-item style="box-shadow:none !important;" title="" :id="'item5'">
                    <template v-slot:header>
                        <span class="lead collapse-title">{{trans('pdw-certificates',234)}}</span>
                        <span class="text-muted ml-1"> {{trans('pdw-optional',234)}} </span>
                     </template>
                    <students-applicants-profile-wizard-document-component :id_document_const_type="10" :maxFilesNumber="20" :itemData="itemData" :document="itemData.document_certificate" />
                </app-collapse-item>
                <small class="text-danger ml-1"></small>
            </b-card>
        </app-collapse>

        <b-card class="mb-3">
            <div class="app-card-profile-title mb-3">
                {{trans('pdw-apply-payroll-tax-credit',234)}}
                <img id="popover-target-1" tabindex="0" src="@/assets/images/icons/info-button-students.svg" height="18px" style="vertical-align: text-top; color: #b5d5f3" class="cursor-pointer" @click="showLoonInfo" />
            </div>
            <app-radio-group v-model="citemData.id_payroll_taxes" class="app-local-radio-vog" :label="trans('pdw-indicate-payroll-tax',234)" :stacked="true" :options="payroll_taxes" validatorName="PayrollTaxes" validatorRules="required" :validatorCustomMessage="{ required: trans('required-field',256) }" />
        </b-card>

        <b-card class="mb-3">
            <students-applicants-profile-wizard-pension-component :itemData="itemData" />
        </b-card>

        <b-popover target="popover-target-1" triggers="focus" no-fade custom-class="app-local-popover">
            <template #title>
               {{trans('pdw-apply-payroll-tax-credit',234)}}
            </template>
            <p class="app-popover-text" v-html="trans('pdw-yes-with-payroll-tax-discount',234)"></p>
            <p class="app-popover-text" v-html="trans('pdw-yes-without-payroll-tax-credit',234)"></p>
            <!--<p class="app-popover-text" v-html="trans('pdw-no-no-payroll-tax-is-calculated',234)"></p>
            <p class="app-popover-text" v-html="trans('pdw-anonymous-rate',234)"></p>-->
        </b-popover>

        <b-popover target="popover-target-2" triggers="focus" no-fade>
            <template #title>
                {{trans('pdw-reference',234)}}
            </template>
            <p class="app-popover-text">{{trans('pdw-reference-info', 234)}}</p>
        </b-popover>
    </div>
</template>
<script>
import { BPopover } from "bootstrap-vue";
import AppCollapse from "@core/components/AppCollapse/AppCollapse.vue";
import AppCollapseItem from "@core/components/AppCollapse/AppCollapseItem.vue";
import StudentsApplicantsProfileWizardDocumentIdComponent from './StudentsApplicantsProfileWizardDocumentIdComponent.vue';
import StudentsApplicantsProfileWizardDocumentComponent from './StudentsApplicantsProfileWizardDocumentComponent.vue';
import StudentsApplicantsProfileWizardBankDetailsComponent from './StudentsApplicantsProfileWizardBankDetailsComponent.vue';
import StudentsApplicantsProfileWizardReferenceComponent from './StudentsApplicantsProfileWizardReferenceComponent.vue';
import StudentsApplicantsProfileWizardPensionComponent from './StudentsApplicantsProfileWizardPensionComponent.vue';

export default {
    components: {
        AppCollapse,
        AppCollapseItem,
        BPopover,
        StudentsApplicantsProfileWizardDocumentIdComponent,
        StudentsApplicantsProfileWizardDocumentComponent,
        StudentsApplicantsProfileWizardBankDetailsComponent,
        StudentsApplicantsProfileWizardReferenceComponent,
        StudentsApplicantsProfileWizardPensionComponent
    },

    props: ["itemData"],

    computed: {
        citemData:{
            get() { return this.itemData },
            set(value) { this.$emit("update:itemData", value) }
        }
    },

    data() {
        return {
            document_type:[
                { value: 1, text: this.trans('pdw-passport',234) },
                { value: 2, text: this.trans('pdw-id',234) },
                { value: 3, text: this.trans('pdw-residence-document',234) },
            ],
            payroll_taxes: [
                { value: 1, text: this.trans('pdw-yes-with-payroll-tax-opt',234) },
                { value: 2, text: this.trans('pdw-yes-without-payroll-opt',234) },
                //{ value: 3, text: this.trans('no-option',253) },
                //{ value: 4, text: this.trans('pdw-anonymous-rate-opt',234) },
            ],

            vog_option: [
                { value: 1, htmltext: this.trans('pdw-add-vog',234) },
                { value: 0, htmltext: this.trans('pdw-apply-for-vog',234) },
            ],
            refresh_bank_error: 0,
            refresh_id_error: 0,
            refresh_reference_error: 0,
        };
    },

    methods: {
        showLoonInfo() {
            this.$root.$emit("bv::show::popover", "popover-target-1");
        },

         showReferenceInfo(event) {
            event.stopPropagation();
            this.$root.$emit("bv::show::popover", "popover-target-2");
        },

        checkId(){
            if(this.citemData.bsn_number != null && this.citemData.document_type != null && this.citemData.bsn_number != '' && this.citemData.document_type != ''){
                var testnumber = this.citemData.bsn_number;
                if (testnumber.toString().length != 9) {
                    return;
                } else {
                    var sum = 0;
                    var c = 0;
                    for (var i = 0; i <= 7; i++) {
                        c = testnumber.toString().substring(i, i + 1);
                        sum = sum + (9 - i) * c;
                    }
                    c = testnumber.toString().substring(8, 9);
                    c = c * -1;
                    sum = sum + c;
                    if (sum % 11 == 0) {
                        this.refresh_id_error++;
                    }
                }
            }
        }
    },
};
</script>

<style>
.popover {
    max-width: 360px !important;
}

[class*="collapse-"] .card .card-body{
    padding: 1.5rem;   
}

.app-local-radio-vog .custom-control-label {
    height: auto !important
}
</style>
<style scoped>
.app-local-popover{
    width: calc(100vw - 60px);
}

.app-local-label{
    font-size: 13px;
    color: #174066;
}
</style>
