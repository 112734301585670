<template>
    <div>
        <b-form @submit.prevent>
            <b-row> 
                <b-col>                    
                    <b-row v-if="itemData.document_id.file_front">
                        <b-col :cols="is_mobile.documents ? 12 : 8">
                            <span class="font-weight-bolder"> {{trans('pdw-front-side',234)}} </span>
                            <span>{{itemData.document_id.file_front.file_name_user}}</span>
                        </b-col>
                        <b-col :cols="is_mobile.documents ? 6 : 2">
                            <span class="text-right font-weight-bolder"> {{ itemData.document_id.file_front.size }} MB </span>
                        </b-col>
                        <b-col :cols="is_mobile.documents ? 6 : 2">
                            <b-link href="#" @click="deleteFile(itemData.document_id.file_front.id_document_data_main, 1)">
                                 <span class="text-right" v-if="!fDeletingFront">{{trans('verwijder',255)}}</span>
                                 <b-spinner style="color:#339994" small v-if="fDeletingFront" />
                            </b-link>
                        </b-col>        
                    </b-row>
                     <b-row v-if="itemData.document_id.file_back">
                        <b-col :cols="is_mobile.documents ? 12 : 8">
                            <span class="font-weight-bolder"> {{trans('pdw-back-side',234)}} </span>
                            <span>{{itemData.document_id.file_back.file_name_user}}</span>
                        </b-col>
                        <b-col :cols="is_mobile.documents ? 6 : 2">
                            <span class="text-right font-weight-bolder"> {{ itemData.document_id.file_back.size }} MB </span>
                        </b-col>
                        <b-col :cols="is_mobile.documents ? 6 : 2">
                            <b-link href="#" @click="deleteFile(itemData.document_id.file_back.id_document_data_main, 2)">
                                 <span class="text-right" v-if="!fDeletingBack">{{trans('verwijder',255)}}</span>
                                 <b-spinner style="color:#339994" small v-if="fDeletingBack" />
                            </b-link>
                        </b-col>        
                    </b-row>  
                </b-col>
            </b-row>    
            <b-row v-bind:class="{ 'mt-2': itemData.document_id.file_front || itemData.document_id.file_back }">
                <b-col v-if="!itemData.document_id.file_front">                  
                        <div class="image-upload-wrap">
                            <input class="file-upload-input" type="file" accept="image/png, image/jpeg, image/jpg, application/pdf" v-on:change="addFile($event, 1)"/>
                            <div class="drag-text">
                                <img src="@/assets/images/icons/download-cloud-students.svg"  height="40px" v-if="!fUploadingFront"/>
                                <b-spinner v-if="fUploadingFront"  style="margin-top: 4px; color:#339994; margin-bottom:6px"/>
                                <div>
                                     <small class="text-muted"> {{trans('pdw-drag-or-select-the',234)}} </small>
                                     <small class="font-weight-bolder"> {{trans('pdf-front-side',234)}}</small>
                                     <small class="text-muted">, {{trans('pdw-not-more-file-size',234)}} </small>
                                     <small class="text-muted"> {{trans('pdw-id-format-jpg-png-pdf',234)}} </small>
                                </div>                                
                            </div>
                        </div>                    
                </b-col>
                <b-col v-if="!itemData.document_id.file_back">                  
                        <div class="image-upload-wrap">
                            <input class="file-upload-input" type="file" accept="image/png, image/jpeg, image/jpg, application/pdf" v-on:change="addFile($event, 2)"/>
                            <div class="drag-text">                                
                                <img src="@/assets/images/icons/download-cloud-students.svg"  height="40px"  v-if="!fUploadingBack"/>
                                <b-spinner v-if="fUploadingBack"  style="margin-top: 4px; color:#339994; margin-bottom:6px"/>
                                 <div>
                                    <small class="text-muted"> {{trans('pdw-drag-or-select-the',234)}} </small>
                                    <small class="font-weight-bolder"> {{trans('pdw-back-side-word',234)}}</small>
                                    <small class="text-muted">, {{trans('pdw-not-more-file-size',234)}} </small>
                                    <small class="text-muted"> {{trans('pdw-id-format-jpg-png-pdf',234)}}</small>
                                </div> 
                            </div>
                        </div>
                </b-col>
            </b-row>
        </b-form>
    </div>
</template>
<script>
import { BSpinner} from "bootstrap-vue";
import axios from "@axios";
export default {
    components: {
        BSpinner
    },

    props:{
        itemData: Object,
        refresh_id_error: {}
    },

    watch: {
        "citemData.document_id": {
            handler: function(val) {
                if(this.citemData.document_id.file_front && this.citemData.document_id.file_back && this.citemData.bsn_number != null && this.citemData.document_type != null && this.citemData.bsn_number != '' && this.citemData.document_type != ''){
                    this.cRefreshIdError++;
                }            
            },
            deep:true,
        },
    },

    computed:{
        citemData: {
            get() { return this.itemData },
            set() { this.$emit('update:itemData', false) }, 
        },

        cRefreshIdError:{
            get() { return this.refresh_id_error },
            set(value) { this.$emit("update:refresh_id_error", value) }
        },
    },

    data() {
        return {
            fUploadingFront: false,
            fUploadingBack: false,
            fDeletingFront: false,
            fDeletingBack: false
        };
    },
    
    methods: {
        addFile(e, id_document_const_type) {
           
            var file = e.target.files[0];

            if(!file.type.match('image/jp.*') && !file.type.match('image/png.*') && !file.type.match('application/pdf.*')) {
                alert(this.trans('pdw-only-jpg-png-pdf-error',234));
                file = "";
                return;
            }

            if(file.size > 15728640){
                alert(this.trans('pdw-too-large-file', 234));
                file = "";
                return;
            }

            const config = {
                headers: { "content-type": "multipart/form-data" },
            };

            let formData = new FormData();
            formData.append("id_person_data_main", this.itemData.id_person_data_main);
            formData.append("file", file);
            formData.append("file_name", file.name);
            formData.append("id_document_const_type", id_document_const_type);
            formData.append("file_size", file.size);

            if (id_document_const_type === 1) {
                this.fUploadingFront = true;
            } else if(id_document_const_type === 2) {
                this.fUploadingBack = true;
            }

            axios
                .post("students/data-wizard/id/addFile/" + this.$route.params.token, formData, config)
                .then((response) => {                             
                            
                            this.parseResponse(response);
                            
                            if (id_document_const_type === 1) {
                                this.fUploadingFront = false;
                            } else if(id_document_const_type === 2) {
                                this.fUploadingBack = false;
                            } 
                        }
                     )
                .catch(function(error) {

                    if (id_document_const_type === 1) {
                        this.fUploadingFront = false;
                    } else if(id_document_const_type === 2) {
                        this.fUploadingBack = false;
                    } 

                    console.log(error);
                }); 
        },

        deleteFile: function(id_document_data_main, id_document_const_type) {
            
            if (id_document_const_type === 1) {
                this.fDeletingFront = true;
            } else if(id_document_const_type === 2) {
                this.fDeletingBack = true;
            }

             let formData = new FormData();
            formData.append("id_person_data_main", this.itemData.id_person_data_main);
            formData.append("id_document_data_main", id_document_data_main);
            formData.append("id_document_const_type", id_document_const_type);
            
            axios
                .post("students/data-wizard/id/deleteFile/" + this.$route.params.token, formData)
                .then((response) => {
                    this.parseResponse(response);

                    if (id_document_const_type === 1) {
                        this.fDeletingFront = false;
                    } else if(id_document_const_type === 2) {
                        this.fDeletingBack = false;
                    }
                })
                .catch((error) => {

                    if (id_document_const_type === 1) {
                        this.fDeletingFront = false;
                    } else if(id_document_const_type === 2) {
                        this.fDeletingBack = false;
                    }

                    console.error(`error during deleting files: ${error}`);
                });
        },

        parseResponse: function(response) {
            if (response.data != undefined && response.data != null) {
                this.citemData.document_id.file_front = response.data.file_front;
                this.citemData.document_id.file_back = response.data.file_back;
                this.citemData.document_id.status = response.data.status;
            }
        }
    },    

};
</script>

<style scoped>
.file-upload-content {
    display: none;
    text-align: center;
}

.file-upload-input {
    position: absolute;
    margin: 0;
    padding: 0;
    width: 100%;
    height: 100%;
    outline: none;
    opacity: 0;
    cursor: pointer;
}

.image-upload-wrap {
    border: 1px dashed #63cec9;
    border-radius: 5px;
    position: relative;
    transition: 1s;
}

.image-dropping,
.image-upload-wrap:hover {
    background-color: #d6ebea62;
}
.drag-text {
    text-align: center;
    padding: 20px 10px;
}

.drag-text p {
    color: #000000;
    font-size: 12px;
}

.fileContainer {
    font-size: 12px;
    border: none;
    padding: 0 0 10px 0;
}
.fileContainer:hover {
    background-color: transparent;
}
.deleteButton {
    color: #71e6ee;
    cursor: pointer;
    float: left;
}
.files {
    width: 43%;
}
.loading {
    margin-left: 40%;
}
.uploadIcon {
    width: 13%;
}

</style>
