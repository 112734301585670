<template>
    <div>
        <div class="app-card-profile-title mb-3">
            {{trans('pdw-retirement-history',234)}}
            <img tabindex="0" src="@/assets/images/icons/info-button-students.svg" height="18px" style="vertical-align: text-top;" class="app-cursor-pointer" @click="showRetirementInfo" />
        </div>     
        
        <b-row>
            <b-col md="12" lg="6">
                <app-radio-group v-model="cItemData.is_pension" :label="trans('pdw-is-pension-question',234)" :options="pension_options" :validatorCustomMessage="{ required: trans('required-field', 256) }" validatorRules="required" />
            </b-col>
            <b-col md="12" lg="6">
                <app-radio-group v-if="cItemData.is_pension == 1" v-model="cItemData.pension_type" :label="trans('pdw-pension-type-question',234)" :options="pension_types" :validatorCustomMessage="{ required: trans('required-field', 256) }" validatorRules="required" />
                <app-input v-if="cItemData.is_pension == 1 && cItemData.pension_type == 1" v-model="cItemData.previous_worked_weeks" :label="trans('pdw-number-of-weeks-accrued',234)" validatorRules="required|correct-pension-count" :validatorName="trans('pdw-number-of-weeks-accrued',234)" mask="##" class="mt-2" />
             </b-col>
        </b-row>
    <students-applicants-profile-wizard-pension-info v-if="mPensionInfo.show" :show.sync="mPensionInfo.show"/>
    </div>
</template>
<script>
import StudentsApplicantsProfileWizardPensionInfo from './StudentsApplicantsProfileWizardPensionInfo.vue';

export default {

    props: ["itemData"],

    components: {
        StudentsApplicantsProfileWizardPensionInfo
    },

    computed: {
        cItemData:{
            get() { return this.itemData },
            set(value) { this.$emit("update:itemData", value) }
        },
    },

    data(){
        return{ 
            mPensionInfo: { show: false, args: {}, result: 0, return: null },
            pension_options: [
                { value: 1, text: this.trans('yes-option',253)},
                { value: 0, text: this.trans('no-option',253)  },
            ],
            pension_types: [
                { value: 1, text: this.trans('pdw-basic-scheme',234) },
                { value: 2, text: this.trans('pdw-plus-scheme',234)  },
            ],
        }
    },

    methods: {
        showRetirementInfo() {
            this.mPensionInfo.show = true;
        },
    }
    
}
</script>
<style scoped>

</style>